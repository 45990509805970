@mixin card($cardName, $childWidth, $childHeight) {
  .#{$cardName} {
    align-items: center;
    height: $childHeight;
    margin-right: 1.3rem;
    margin-bottom: 1.3rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.08);
    position: relative;
    transition: box-shadow .2s ease-in-out;
    width: 100%;
    max-width: $childWidth;
    background: #fff;
    border-radius: 14px;
    border:1px solid var(--light-gray);

    &:hover {
      box-shadow: 0 4px 12px rgba(50, 50, 93, 0.08);
      transition: box-shadow .3s ease-in-out;
    }


    &.checked {
      border-color: var(--primary);

      &:after {
        font: var(--fa-font-light);
        content: "\f00c";
        position: absolute;
        top: -10px;
        right: -10px;
        color: #fff;
        font-size: 15px;
        background: var(--primary);
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@mixin icon-wrapper-circle($iconWrapperName, $size: 56px, $background: #1c2870, $color: #fff, $fontSize: 32px) {
  .#{$iconWrapperName} {
    width: $size;
    height: $size;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    background: $background;
    color: $color;
    font-size: $fontSize;
  }
}

@mixin placeholder-color($color: var(--black)) {
  //PLACEHOLDER
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color !important;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color !important;
  }
}

@mixin square {
  content: "";
  display: block;
  background: #757a97;
  width: .7rem;
  height: .7rem;
  position: absolute;
  top: .9rem;
  margin-left: -1.5rem;
}
